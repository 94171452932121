<template>
  <div class="home" @click.stop="selectlist = fasle">
    <div class="header_top">
      <handleback></handleback>
      <div>我的背包</div>
      <div>玩法说明</div>
    </div>
    <div class="header column">
      <div class="header_I left">
        <div class="header_I_I">饰品总数 : {{ totalOrnamentNumber }}</div>
        <div class="header_I_I">饰品总价值 : {{ totalOrnamentPrice }}</div>
      </div>
      <div class="header_II left">
        <div class="header_II_I">
          已选饰品总数 : {{ isAll ? totalOrnamentNumber : recyleMoney.index }}
        </div>
        <div class="header_II_I">
          已选饰品总价值 : {{ isAll ? totalOrnamentPrice : recyleMoney.money }}
        </div>
      </div>
    </div>
    <div class="main column">
      <div class="main_I space_between">
        <div class="main_I_I center" @click.stop="selectlist = !selectlist">
          {{ selected }}
          <div class="main_I_I_I" v-if="selectlist">
            <div
              class="main_I_I_I_I center"
              @click.stop="
                handlesort(0);
                playAudio();
              "
            >
              默认排序
            </div>
            <div
              class="main_I_I_I_I center"
              @click.stop="
                handlesort(1);
                playAudio();
              "
            >
              按价格从低到高排序
            </div>
            <div
              class="main_I_I_I_I center"
              @click.stop="
                handlesort(2);
                playAudio();
              "
            >
              按价格从高到低排序
            </div>
          </div>
        </div>
        <div class="main_I_II">
          <div class="main_I_II_I">
            <input
              type="text"
              v-model="page.name"
              name
              id
              style="serch"
              placeholder="请输入饰品名称"
            />
            <svg
              @click="handlesearch"
              t="1705982070098"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              p-id="3285"
              width="30"
              height="30"
            >
              <path
                d="M512.034281 76.882018c-240.202711 0-435.165054 194.83136-435.165054 435.124122 0 240.268202 194.96132 435.100586 435.165054 435.100586 59.585073 0 116.371404-12.05557 168.096463-33.743522-13.106506-5.012154-24.790616-14.037714-32.602535-26.979468L544.87013 714.613604c-111.893416 33.626865-236.425687-10.772344-299.55447-115.499547-74.789357-123.859959-34.93465-285.041942 88.921215-359.814925 124.066667-74.738192 285.119713-34.814923 359.861998 89.102341 63.10627 104.58701 44.26311 235.499595-37.545104 318.843792l102.704127 171.745573c8.442276 13.96813 10.727319 29.801746 8.185426 44.703131 108.698654-79.121012 179.662893-206.981075 179.662893-351.687828C947.106214 271.713378 752.261551 76.882018 512.034281 76.882018"
                fill="#3A97E4"
                p-id="3286"
              />
              <path
                d="M385.72862 324.729849c-76.770478 46.324048-101.538581 146.152684-55.153134 223.013213 46.385447 76.905554 146.222269 101.531418 223.180012 55.149041 76.842109-46.310745 101.538581-146.233526 55.153134-223.01219C562.546721 302.902728 462.687386 278.230816 385.72862 324.729849"
                fill="#3A97E4"
                p-id="3287"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="main_II space_between">
        <div class="main_II_I space_between">
          <div
            class="main_II_I_I center btn_click"
            @click="
              openConfirmDelivery();
              playAudio();
            "
          >
            提取
          </div>
          <div
            class="main_II_I_I center btn_click"
            @click="
              openConfirm();
              playAudio();
            "
          >
            分解
          </div>
        </div>
        <div class="main_II_II space_between">
          <div
            class="main_II_II_I space_between"
            @click="
              handleSelectAll();
              playAudio();
            "
            :class="isAll ? 'active' : ''"
          >
            <svg
              t="1707113229515"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="3034"
            >
              <path
                d="M673.858 151.948H198.194C88.903 151.948 0 240.852 0 350.142v475.665C0 935.097 88.903 1024 198.194 1024h475.665c109.29 0 198.194-88.903 198.194-198.194V350.142c-0.001-109.29-88.905-198.194-198.195-198.194z m21.419 320.917l-286.283 286.27c-7.742 7.742-17.884 11.613-28.026 11.613s-20.284-3.871-28.026-11.613L176.774 582.968c-15.484-15.471-15.484-40.581 0-56.052 15.484-15.484 40.568-15.484 56.052 0l148.142 148.142 258.258-258.245c15.484-15.484 40.568-15.484 56.052 0 15.483 15.484 15.483 40.581-0.001 56.052z"
                fill="#727277"
                p-id="3035"
              />
              <path
                d="M825.806 0H350.142c-73.597 0-137.813 40.405-171.997 100.113 6.634-0.529 13.281-1.016 20.048-1.016h475.665c138.426 0 251.045 112.619 251.045 251.045v475.665c0 6.768-0.487 13.414-1.016 20.048C983.595 811.671 1024 747.455 1024 673.858V198.194C1024 88.903 935.097 0 825.806 0z"
                fill="#727277"
                p-id="3036"
              />
            </svg>
            全选
          </div>
          <div class="main_II_II_II">
            已选：{{ isAll ? totalOrnamentNumber : recyleMoney.index }}
          </div>
        </div>
      </div>
    </div>
    <div class="bottom grade3" @scroll="scroll">
      <div
        @click="
          handleSelect(item);
          playAudio();
        "
        class="bottom_I"
        :class="item.isselect ? 'selectbg' : ''"
        :style="{ 'background-image': `url(${item.levelImg})` }"
        v-for="(item, index) in arr"
        :key="index"
      >
        <div class="bottom_I_I center">
          <img :src="item.imageUrl" alt width="100%" />
        </div>
        <div class="textover-f">{{ item.shortName || item.name }}</div>
        <div>
          <div class="money-money">
            <money class="money"></money>
            {{ item.ornamentsPrice }}
          </div>
        </div>
      </div>
    </div>
    <div class="out" v-if="Confirm">
      <div class="out_I animate__animated animate__slideInDown" ref="confirmel">
        {{ isAll ? "确定清空背包吗" : "确定要分解吗？" }}
        <div class="out_I_I space_between">
          <div
            class="out_I_I_I center btn_click"
            @click="
              cancelConfirm();
              playAudio();
            "
          >
            取消
          </div>
          <div
            class="out_I_I_II center btn_click"
            @click="
              handledecompose();
              playAudio();
            "
          >
            <div v-if="onsubmit">确认</div>
            <div v-else>
              <i class="el-icon-loading"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="out" v-if="confirmDelivery">
      <div class="out_I animate__animated animate__slideInDown" ref="confirmel">
        {{
          isAll
            ? "确定提取背包内所有物品吗"
            : `确定要提取${selectarr.length}件物品吗？`
        }}
        <div class="out_I_I space_between">
          <div
            class="out_I_I_I center btn_click"
            @click="
              cancelConfirm();
              playAudio();
            "
          >
            取消
          </div>
          <div
            class="out_I_I_II center btn_click"
            @click="
              handledelivery();
              playAudio();
            "
          >
            <div v-if="onsubmit">确认</div>
            <div v-else>
              <i class="el-icon-loading"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPackSack,
  decompose,
  delivery,
  getUserInfo,
  packSackGlobalData,
} from "@/api/index";
export default {
  data() {
    return {
      onsubmit: true,
      Confirm: false,
      confirmDelivery: false,
      isok: false,
      isselectall: false,
      number: 20,
      selectlist: false,
      searchTerm: "",
      selected: "默认排序",
      arr: [],
      isAll: false,
      selectarr: [],
      page: {
        page: 1,
        size: 12,
        orderByFie: 1,
        orderByType: 2,
        name: "",
      },
      isOver: "加载中",
      totalOrnamentNumber: 0,
      totalOrnamentPrice: 0,
    };
  },
  computed: {
    recyleMoney() {
      let money = 0;
      let index = 0;
      this.arr.forEach((item) => {
        if (item.isselect == true) {
          money += item.ornamentsPrice * 1;
          index++;
        }
      });
      return { money: money.toFixed(2), index };
    },
  },
  mounted() {
    this.getlist();
  },

  methods: {
    playAudio() {
      this.$store.commit("playAudio");
    },
    scroll(e) {
      this.event = e;
      if (
        e.srcElement.scrollTop + e.srcElement.clientHeight >=
        e.srcElement.scrollHeight
      ) {
        // console.log(11);

        if (this.isOver == "加载中") {
          this.page.page++;
          setTimeout(() => {
            this.getlist();
          }, 5);
        }
      }
    },
    openConfirm() {
      if (this.selectarr.length == 0) {
        this.$message({
          message: "请先选择饰品再操作",
          type: "warning",
          customClass: "log_warning",
        });
      } else {
        this.Confirm = true;
      }
    },
    openConfirmDelivery() {
      if (this.selectarr.length == 0) {
        this.$message({
          message: "请先选择饰品再操作",
          type: "warning",
          customClass: "log_warning",
        });
      } else {
        this.confirmDelivery = true;
      }
    },
    handledelivery() {
      if (this.selectarr.length == 0) {
        this.$message({
          message: "请先选择饰品再操作",
          type: "warning",
          customClass: "log_warning",
        });
      } else {
        const selectedItems = this.arr.filter((item) => item.isselect === true);
        this.selectarr = selectedItems.map((item) => item.id);
        delivery({ isAll: this.isAll, packSackIds: this.selectarr }).then(
          (res) => {
            if (res.data.code == 200) {
              if (this.isAll) {
                this.isAll = false;
              }
              this.$message({
                message: res.data.msg,
                type: "success",
                customClass: "log_success",
              });
              // this.getlist();
              this.confirmDelivery = false;
              this.arr = this.arr.filter((item) => {
                return !this.selectarr.includes(item.id);
              });
              this.getGlobalData();
            } else {
              this.$message({
                message: res.data.msg,
                type: "warning",
                customClass: "log_warning",
              });
            }
          }
        );
      }
    },
    cancelConfirm() {
      const element = this.$refs.confirmel;
      element.className += " animate__slideOutDown";

      setTimeout(() => {
        this.Confirm = false;
        this.confirmDelivery = false;
      }, 400);
    },
    handleSelectAll() {
      if (this.isAll == true) {
        // 取消全选
        for (let i = 0; i < this.arr.length; i++) {
          this.arr[i].isselect = false;
        }
        const selectedItems = this.arr.filter((item) => item.isselect === true);
        this.selectarr = selectedItems.map((item) => item.id);

        // if (this.selectarr.length == this.arr.length) {
        this.isAll = false;
        // }
      } else if (this.isAll == false) {
        // 全选
        for (let i = 0; i < this.arr.length; i++) {
          this.arr[i].isselect = true;
        }
        const selectedItems = this.arr.filter((item) => item.isselect === true);
        this.selectarr = selectedItems.map((item) => item.id);

        if (this.selectarr.length == this.arr.length) {
          this.isAll = true;
        }
      }
    },
    handlesort(res) {
      this.page.page = 1;
      this.arr = [];
      if (res === 1) {
        this.selected = "按价格从低到高排序";
        this.page.orderByFie = 2;
        this.page.orderByType = 1;
        this.getlist();
        // this.arr.sort((a, b) => a.ornamentsPrice - b.ornamentsPrice);
      } else if (res === 2) {
        this.selected = "按价格从高到低排序";
        this.page.orderByFie = 2;
        this.page.orderByType = 2;
        this.getlist();
        // this.arr.sort((a, b) => b.ornamentsPrice - a.ornamentsPrice);
      } else if (res === 0) {
        this.selected = "默认排序";
        this.page.orderByFie = 1;
        this.page.orderByType = 2;
        this.getlist();
      }
      this.selectlist = false;
    },
    handlesearch() {
      this.page.page = 1;
      this.arr = [];
      this.page.orderByFie = 1;
      this.page.orderByType = 2;
      this.getlist();
      // if (this.searchTerm == "") {
      //   this.getlist();
      // } else {
      //   let aa = this.arr.filter((item) => {
      //     return item.itemName.includes(this.searchTerm);
      //   });
      //   this.arr = aa;
      // }
    },
    handleSelect(res) {
      res.isselect = !res.isselect;
      const selectedItems = this.arr.filter((item) => item.isselect === true);
      this.selectarr = selectedItems.map((item) => item.id);
      if (this.selectarr.length == this.totalOrnamentNumber) {
        this.isAll = true;
      } else {
        this.isAll = false;
      }
    },
    handledecompose() {
      this.onsubmit = false;
      const selectedItems = this.arr.filter((item) => item.isselect === true);
      this.selectarr = selectedItems.map((item) => item.id);

      decompose({ isAll: this.isAll, packSackIds: this.selectarr }).then(
        (res) => {
          if (res.data.code === 200) {
            const element = this.$refs.confirmel;
            element.className += " animate__slideOutUp";
            if (this.isAll) {
              this.isAll = false;
            }
            setTimeout(() => {
              this.Confirm = false;
              this.$message({
                message: "分解成功",
                type: "success",
                customClass: "log_success",
              });
              getUserInfo().then((res) => {
                this.user = res.data.data;
                this.$store.commit("USER_INFO", res.data.data);
              });
              this.onsubmit = true;
            }, 400);

            this.arr = this.arr.filter((item) => {
              return !this.selectarr.includes(item.id);
            });
            this.getGlobalData();
          } else {
            this.Confirm = false;
          }
        }
      );
    },
    getGlobalData() {
      packSackGlobalData().then((res) => {
        console.log("背包统计", res.data);
        this.totalOrnamentPrice = res.data.data.totalOrnamentPrice;
        this.totalOrnamentNumber = res.data.data.totalOrnamentNumber;
      });
    },
    getlist() {
      this.getGlobalData();
      getPackSack(this.page).then((res) => {
        console.log("getPackSack", res);
        let arr = [];
        arr = res.data.data;
        if (this.isAll == true) {
          arr = arr.map((item) => ({ ...item, isselect: true }));
        } else {
          arr = arr.map((item) => ({ ...item, isselect: false }));
        }
        this.arr.push(...arr);
        this.isok = true;
        if (res.data.data.length < this.page.size) {
          this.isOver = "暂无更多";
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom {
  height: 362px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: #25f484;
    border-radius: 5px;
  }

  @media (max-width: 550px) {
    &::-webkit-scrollbar {
      width: 2px;
    }
  }
}
.home {
  width: 95%;
  margin: 0 auto;
}
.header_top {
  width: 100%;
  height: 56px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  div:first-child {
  }
  div:last-child {
    color: #fff;
    font-size: 12px;
    text-decoration: underline;
    line-height: 1.5;
    opacity: 0;
  }
}
.header {
  width: 100%;
  height: 140px;
  background: #101115;
  border-radius: 14px 14px 14px 14px;
  opacity: 1;
  overflow: hidden;
  @media (max-width: 550px) {
    height: 100px;
    font-size: 13px;
  }
  .header_II {
    height: 70px;
    width: 100%;
    @media (max-width: 550px) {
      height: 50px;
    }
    .header_II_I {
      width: 49%;
    }
  }
  .header_I {
    height: 70px;
    border-bottom: 1px solid #323436;
    width: 100%;
    @media (max-width: 550px) {
      height: 50px;
    }
    .header_I_I {
      width: 49%;
    }
  }
}
.main {
  margin-top: 14px;
  .main_I {
    width: 100%;
    .main_I_I {
      width: 224px;
      height: 42px;
      background: #323436;
      border-radius: 70px 70px 70px 70px;
      opacity: 1;
      position: relative;
      @media (max-width: 550px) {
        width: 160px;
        height: 30px;

        font-size: 11px;
      }
      .main_I_I_I {
        width: 100%;

        position: absolute;
        top: 44px;
        background: #101115;
        @media (max-width: 550px) {
          top: 34px;
        }

        .main_I_I_I_I {
          width: 100%;
          height: 42px;
          opacity: 1;
          @media (max-width: 550px) {
            font-size: 11px;
            height: 30px;
          }
          font-size: 15px;
          color: #727477;
          &:hover {
            color: #fff;
            background: linear-gradient(
              360deg,
              rgba(37, 244, 132, 0.5) 0%,
              rgba(37, 244, 132, 0.25) 100%
            );
            opacity: 1;
            border: 1px solid #25f484;
          }
        }
        .active {
          background: linear-gradient(
            360deg,
            rgba(37, 244, 132, 0.5) 0%,
            rgba(37, 244, 132, 0.25) 100%
          );
          opacity: 1;
          border: 1px solid #25f484;
        }
      }
    }
    .main_I_II {
      .main_I_II_I {
        width: 100%;
        height: 42px;
        padding: 0 2% 0 5%;
        background: #323436;
        border-radius: 70px 70px 70px 70px;
        opacity: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 550px) {
          width: 185px;
          height: 30px;
        }
        input {
          width: 100%;
          height: 100%;
          border-radius: 35px;
          border: 1px solid #323436;
          color: #fff;
          font-size: 15px;
          background-color: #323436;
          text-indent: 10px;
          outline: none;
        }
      }
    }
  }
  .main_II {
    width: 100%;
    margin-top: 14px;
    .main_II_I {
      width: 227px;
      @media (max-width: 550px) {
        width: 160px;
      }
      .main_II_I_I {
        width: 98px;
        height: 35px;
        border-radius: 35px 35px 35px 35px;
        opacity: 1;
        border: 1px solid #727477;
        font-size: 18px;
        font-weight: bold;
        color: #727477;
        @media (max-width: 550px) {
          width: 70px;
          height: 25px;
          font-size: 13px;
        }
      }
    }
    .main_II_II {
      width: 45%;
      justify-content: right;
      font-size: 18px;
      @media (max-width: 550px) {
        font-size: 13px;
      }
      .main_II_II_I {
        width: 44%;
        .icon {
          width: 24px;
          height: 24px;
          @media (max-width: 550px) {
            width: 17px;
            height: 17px;
          }
        }
      }
      .active {
        svg {
          path {
            fill: #3a97e4;
          }
        }
      }
      .main_II_II_II {
        // width: 44%;
        padding: 0 10px;
      }
    }
  }
}
.bottom {
  margin-top: 14px;
  .bottom_I {
    // border: 2px solid #727277;
    width: 100%;
    height: 175px;
    background-color: rgba(50, 52, 54, 0.25);
    background-size: 100% 100%;
    border-radius: 14px;
    transition: all 0.3s ease;
    font-size: 13px;
    @media (max-width: 550px) {
      height: 125px;

      font-size: 9px;
    }
    .bottom_I_I {
      margin: 0 auto;
      width: 65%;
      height: 112px;
      @media (max-width: 550px) {
        height: 80px;
      }
    }
  }
}

.out {
  width: 525px;
  height: 100vh;
  transform: translateX(-50%);
  position: fixed;
  left: 50%;
  top: 0;
  background: rgba(0, 0, 0, 0.85);
  z-index: 2;
  @media (max-width: 550px) {
    width: 100%;
  }
  .out_I {
    width: 95%;
    margin: 0 auto;
    height: 168px;
    margin-top: 210px;
    background: #101115;
    border-radius: 21px;
    opacity: 1;
    text-align: center;
    font-size: 21px;
    font-weight: 400;
    color: #ffffff;
    padding-top: 35px;
    position: relative;
    overflow: hidden;
    @media (max-width: 550px) {
      height: 120px;
      font-size: 15px;
      padding-top: 25px;
    }
    .out_I_I {
      width: 87%;
      margin: 0 auto;
      margin-top: 35px;
      position: relative;
      z-index: 2;
      .out_I_I_I {
        width: 196px;
        height: 42px;
        background: linear-gradient(
          360deg,
          rgba(114, 116, 119, 0.5) 0%,
          rgba(114, 116, 119, 0.25) 100%
        );
        opacity: 1;
        border: 1px solid #727477;
        @media (max-width: 550px) {
          font-size: 15px;
          width: 140px;
          height: 30px;
        }
      }
      .out_I_I_II {
        width: 196px;
        height: 42px;
        background: linear-gradient(
          360deg,
          rgba(37, 244, 132, 0.5) 0%,
          rgba(37, 244, 132, 0.25) 100%
        );
        opacity: 1;
        border: 1px solid #25f484;
        @media (max-width: 550px) {
          font-size: 15px;
          width: 140px;
          height: 30px;
        }
      }
    }
  }
  .out_I::before {
    content: ""; /*必须设置content属性*/
    width: 100%;
    height: 100%;
    background: linear-gradient(
      -219deg,
      rgba(37, 244, 132, 0.2) 0%,
      rgba(32, 29, 48, 0) 40%,
      rgba(248, 125, 81, 0) 100%
    );
    position: absolute;
    left: 0;
    top: 0;
  }
}
.selectbg {
  border: 2px solid #25f484 !important;
}
</style>
